const fields = {
    reports: {
        Address: {
            Display: 'כתובת',
            Id: 'Address',
            Type: 'FieldStandard',
            Tooltip: 'המיקום בו נרשם הדוח ע"פ הפקח',
            Sortable: false,
            Order: 18,
        },
        Alerts: {
            Display: 'התראות',
            Id: 'Alerts',
            Type: 'FieldAlerts',
            Tooltip: 'התראות על דוח מציינות שלדוח זה קיימים פרמטרים העשויים להשפיע על תהליך הטיפול של החברה',
        },
        ApprovalDate: {
            Display: 'ת. אישור תשלום',
            Id: 'ApprovalDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו הדוח אושר לתשלום מטעם החברה',
            Sortable: false,
            Order: 11,
        },
        ClientComment: {
            Order: 21,
            Display: 'הערת לקוח',
            Id: 'ClientComment',
            Type: 'FieldStandard',
            Tooltip: 'הערת לקוח הינה אישית למשתמש',
            Sortable: false,
        },
        CreateDate: {
            Display: 'ת. יצירה',
            Id: 'CreateDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו הדוח התווסף למערכת',
            Sortable: false,
            Order: 9,
        },
        CurrentAmount: {
            Order: 16,
            Display: 'יתרה לתשלום',
            Id: 'CurrentAmount',
            Type: 'FieldCurrency',
            Tooltip: 'הסכום הנותר לתשלום',
            Sortable: false,
        },
        DateTaken: {
            Order: 10,
            Display: 'ת. עבירה',
            Id: 'DateTaken',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה בו הדוח נתקבל הדוח מאת הפקח',
            Sortable: false,
        },
        Driver: {
            Order: 7,
            Display: 'נהג',
            Id: 'Driver',
            Type: 'FieldDriver',
            Tooltip: 'הנהג ברכב בזמן ביצוע העבירה',
            Sortable: false,
        },
        DriverAmount: {
            Display: 'תשלום נהג',
            Id: 'DriverAmount',
            Type: 'FieldNumeric',
            Tooltip: 'הסכום שנקבע לתשלום מאת הנהג',
            Sortable: false,
            Order: 7.1,
        },
        IsPaidByDriver: {
            Display: 'שולם ע"י נהג',
            Id: 'IsPaidByDriver',
            Type: 'FieldBoolean',
            Tooltip: 'האם הדוח שולם ע"י הנהג',
            Order: 7.2,
        },
        DriverTransferCompleteDate: {
            Display: 'ת. השלמת הסבת נהג',
            Id: 'DriverTransferCompleteDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו נתקבל מהעיריה אישור להסבת הדוח',
            Sortable: false,
        },
        DriverTransferError: {
            Display: 'חוסרים',
            Id: 'DriverTransferError',
            Type: 'FieldStandard',
            Tooltip: 'הסיבה לכך שההסבה נכשלה',
        },
        DriverTransferPdfUrl: {
            Display: 'קובץ הסבת נהג',
            Id: 'DriverTransferPdfUrl',
            mapItem: (row) => row,
            Type: 'FieldFileDriver',
            Tooltip: 'קובץ PDF עם טופס ההסבה של נהג',
        },
        DriverTransferSignedDate: {
            Display: 'ת. חתימת ע"וד להסבת נהג',
            Id: 'DriverTransferSignedDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה בו עו"ד חתם על הסבת הדוח לנהג',
            Sortable: false,
            Order: 25,
        },
        DriverTransferStatus: {
            Display: 'סטטוס הסבת נהג',
            Id: 'DriverTransferStatus',
            Type: 'FieldStandard',
            Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            Order: 23.1,
        },
        DriverTransferredDate: {
            Display: 'ת. הפקת הסבת נהג',
            Id: 'DriverTransferredDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה בו הוגשה ההסבה לעיריה',
            Sortable: false,
            Order: 23,
        },
        HasScreenshot: {
            Display: 'תצלום מסך',
            Id: 'HasScreenshot',
            Type: 'FieldImage',
            Tooltip: 'תצלום מסך של הגשת ההסבה דרך אתר העיריה',
        },
        Id: {
            Order: 0,
            Display: 'מזהה',
            Id: 'Id',
            Type: 'FieldNumeric',
            Tooltip: 'מזהה שורה',
            AdminOnly: true,
            Sortable: false,
        },
        IsPaid: {
            Display: 'שולם',
            Id: 'IsPaid',
            Type: 'FieldBoolean',
            Tooltip: 'סטטוס תשלום הדוח',
        },
        LastPayDate: {
            Order: 13,
            Display: 'ת. אחרון לתשלום',
            Id: 'LastPayDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך האחרון הנמסר ע"פ העיריה לתשלום הדוח',
            Sortable: false,
        },
        ManagerComment: {
            Display: 'הערת מנהל',
            Id: 'ManagerComment',
            Type: 'FieldStandard',
            Tooltip: 'הערת מנהל ניתנת לצפייה ע"י מנהל בלבד',
            AdminOnly: true,
            Sortable: false,
            Order: 22,
        },
        MuniIdNumber: {
            Display: 'מזהה עיריה',
            Id: 'MuniIdNumber',
            Type: 'FieldNumeric',
            Tooltip: 'הח.פ/ת.ז של מבצע העבירה כפי שמופיע במאגר נתוני העיריה',
            Sortable: false,
            Order: 3,
        },
        MuniReferenceNumber: {
            Display: 'אסמכתה ירושלים',
            Id: 'MuniReferenceNumber',
            Type: 'FieldNumeric',
            Tooltip: 'הח.פ/ת.ז של מבצע העבירה כפי שמופיע בעירית ירושלים',
            Order: 4,
        },
        Municipality: {
            Order: 17,
            Display: 'עיריה',
            Id: 'Municipality',
            Type: 'FieldStandard',
            Tooltip: 'הרשות שמטעמה נתקבל הדוח',
            Sortable: false,
        },
        OriginalAmount: {
            Order: 14,
            Display: 'סכום מקור',
            Id: 'OriginalAmount',
            Type: 'FieldCurrency',
            Tooltip: 'הסכום המקורי שנתקבל בזמן מתן הדוח',
            Sortable: false,
        },
        OwnerName: {
            Display: 'חברה',
            Id: 'OwnerName',
            Type: 'FieldStandard',
            Tooltip: 'הבעלים המקורי של הרכב כפי שמצויין במשרד הרישוי',
            Order: 2.2,
        },
        PartialPaidAmount: {
            Display: 'תשלום חלקי',
            Id: 'PartialPaidAmount',
            Type: 'FieldNumeric',
            Tooltip: 'החלק ששולם עד כה',
            Sortable: false,
            Order: 15,
        },
        PaymentDate: {
            Display: 'ת. תשלום',
            Id: 'PaymentDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו נתקבל תשלום מלא/חלקי בעבור הדוח',
            Sortable: false,
        },
        PaymentReferenceNumber: {
            Display: 'אסמכתה',
            Id: 'PaymentReferenceNumber',
            Type: 'FieldStandard',
            Tooltip: 'האסמכתה כפי שניתנה מהעיריה בגין תשלום חלקי/מלא',
            Sortable: false,
        },
        PaymentUrl: {
            Display: 'דף תשלום',
            Id: 'PaymentUrl',
            Type: 'FieldPayUrl',
            Tooltip: 'לינק לדף התשלום של הרשות בה נתקבל הדוח',
            Width: 100,
            align: 'center',
        },
        PlateNumber: {
            Order: 5,
            Display: 'לוחית רישוי',
            Id: 'Vehicle',
            Type: 'FieldEnum',
            onClick: () => { },
            Tooltip: 'לוחית הרישוי של הרכב בזמן העבירה',
            Sortable: false,
            Namespace: 'vehicles',
        },
        VehiclePlateNumber: {
            Order: 5,
            Display: 'לוחית רישוי',
            Id: 'PlateNumber',
            Type: 'FieldStandard',
            onClick: () => { },
            Tooltip: 'לוחית הרישוי של הרכב בזמן העבירה',
            Sortable: false,
        },
        Profile: {
            Display: 'לקוח',
            Id: 'TransferredTo',
            Type: 'FieldEnum',
            Tooltip: 'החברה אליה הרכב משוייך בזמן קבלת הדוח',
            Sortable: false,
            Namespace: 'clients',
            Order: 6,
        },
        ProfileName: {
            Display: 'לקוח',
            Id: 'ProfileName',
            Type: 'FieldStandard',
            Tooltip: 'החברה אליה הרכב משוייך בזמן קבלת הדוח',
            Sortable: false,
            Order: 2.3,
        },
        Reason: {
            Display: 'סיבת הדוח',
            Id: 'Reason',
            Type: 'FieldStandard',
            Tooltip: 'סיבת העבירה כפי שמופיעה בדו"ח',
            Sortable: false,
        },
        RelevantLaw: {
            Display: 'חוק מסמיך',
            Id: 'RelevantLaw',
            Type: 'FieldStandard',
            Tooltip: 'החוק הרלבנטי בגינו ניתן הדו"ח',
        },
        ReportCause: {
            Order: 19,
            Display: 'סעיף העבירה',
            map: (row) => row?.Name,
            Id: 'ReportCause',
            Type: 'FieldStandard',
            Tooltip: 'סעיף העבירה בתקנות בגינם ניתן הדו"ח',
            Sortable: false,
        },
        ReportNumber: {
            Order: 1,
            Display: 'מספר דוח',
            Id: 'ReportNumber',
            Type: 'FieldLink',
            Tooltip: 'מספר הדו"ח ע"פ הרשות',
            Sortable: false,
        },
        Status: {
            Order: 22,
            Display: 'סטטוס',
            Id: 'Status',
            Type: 'FieldStatus',
            Tooltip: 'סטטוס הדוח ע"פ העיריה',
            Sortable: false,
            Width: 130,
            align: 'center',
        },
        TransferCompleteDate: {
            Display: 'ת. הסבה לחברה',
            Id: 'TransferCompleteDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו נתקבל מהעיריה אישור להסבת הדוח',
            Sortable: false,
        },
        TransferError: {
            Display: 'שגיאת הסבה לחברה',
            Id: 'TransferError',
            Type: 'FieldStandard',
            Tooltip: 'הסיבה לכך שההסבה נכשלה',
            Sortable: false,
        },
        TransferredFromName: {
            Display: 'חברה',
            Id: 'TransferredFrom',
            Type: 'FieldEnum',
            Tooltip: 'הבעלים המקורי של הרכב כפי שמצויין במשרד הרישוי',
            Order: 2.2,
        },
        TransferredTo: {
            Display: 'לקוח',
            Id: 'TransferredTo',
            Type: 'FieldEnum',
            Tooltip: 'הבעלים החדשים של הרכב כפי שמצויין במשרד הרישוי',
            Order: 2.2,
        },
        TransferPdfUrl: {
            Display: 'קובץ הסבה לחברה',
            Id: 'TransferPdfUrl',
            mapItem: (row) => row,
            Type: 'FieldFile',
            Tooltip: 'קובץ PDF עם טופס ההסבה של החברה',
        },
        TransferPdfUrlAdmin: {
            Display: 'קובץ הסבה לחברה',
            Id: 'TransferPdfUrl',
            mapItem: (row) => row,
            Type: 'FieldFileByTransferId',
            Tooltip: 'קובץ PDF עם טופס ההסבה של החברה'
        },
        TransferSignedDate: {
            Display: 'ת. חתימת ע"וד להסבת חברה',
            Id: 'TransferSignedDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה בו עו"ד חתם על הסבת הדוח לחברה',
            Sortable: false,
        },
        TransferStatus: {
            Display: 'סטטוס הסבת חברה',
            Id: 'TransferStatus',
            Type: 'FieldStandard',
            Tooltip: 'סטטוס ההסבה של חברה ע"פ המערכת',
            Sortable: false,
            Order: 24,

        },
        TransferredDate: {
            Display: 'ת.  הפקת הסבת חברה',
            Id: 'TransferredDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה בו הוגשה ההסבה לעיריה',
            Sortable: false,
            Order: 23,
        },
        VehicleType: {
            Order: 2,
            Display: 'סוג הרכב',
            Id: 'VehicleType',
            Type: 'FieldVehicleType',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        VerificationDate: {
            Display: 'ת. אימות',
            Id: 'VerificationDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה האחרונים בהם המערכת וידאה מול העיריה את אמינות הנתונים',
            AdminOnly: true,
            Sortable: false,
            Order: 12,
        },

    },
    lessons: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        CourseNumber: {
            Order: 2,
            Display: 'מספר קורס',
            Id: 'courseId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
        },
        LessonName: {
            Order: 3,
            Display: 'שם יחידת הלימוד',
            Id: 'name',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        LessonColor: {
            Order: 4,
            Display: 'צבע',
            Id: 'btnColor',
            SortField: 'Type',
            Type: 'FieldColor',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 5,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        LessonType: {
            Order: 6,
            Display: 'סוג',
            Id: 'lessonType',
            SortField: 'Type',
            Type: 'FieldLessonType',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SortOrder: {
            Order: 7,
            Display: 'סדר',
            Id: 'sortOrder',
            SortField: 'Type',
            Type: 'FieldSortOrder',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        IsHidden: {
            Order: 8,
            Display: 'מוסתר',
            Id: 'isHidden',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Entity: {
            Order: 9,
            Display: 'ישות',
            Id: 'lessons',
            Type: 'FieldEntity',
            EntityType: 'יחידת לימוד'
        }
        // SupportingMaterials: {
        //     Order: 4,
        //     Display: 'חומרי עזר',
        //     Id: 'supportingMaterials',
        //     Type: 'FieldBoolean',
        //     Tooltip: 'יצרן הרכב',
        //     AdminOnly: false,
        //     Sortable: false,
        // },
    },
    books: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Title: {
            Order: 2,
            Display: 'כותרת',
            Id: 'title',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Subtitle: {
            Order: 3,
            Display: 'תת-כותרת',
            Id: 'subtitle',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Author: {
            Order: 4,
            Display: 'יוצר',
            Id: 'author',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Link: {
            Order: 5,
            Display: 'קישור',
            Id: 'link',
            Type: 'FieldCustomerLink',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 6,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    courseBooks: {
        ImageUrl: {
            Order: 1,
            Display: 'תמונה',
            Id: 'imageUrl',
            Type: 'FieldImage',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Id: {
            Order: 2,
            Display: 'מזהה',
            Id: 'bookId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        BookName: {
            Order: 3,
            Display: 'כותרת',
            Id: 'bookName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonName: {
            Order: 4,
            Display: 'יחידת לימוד',
            Id: 'lessonName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        BookAuthor: {
            Order: 5,
            Display: 'יוצר',
            Id: 'bookAuthor',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SortOrder: {
            Order: 6,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 7,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    coupons: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 2,
            Display: 'שם קופון',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Code: {
            Order: 3,
            Display: 'קוד קופון',
            Id: 'code',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        DiscountPercentage: {
            Order: 4,
            Display: 'אחוז הנחה',
            Id: 'discountPercentage',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        DiscountAmount: {
            Order: 5,
            Display: 'הנחה בשקלים',
            Id: 'discountAmount',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ExpirationDate: {
            Order: 6,
            Display: 'תאריך תפוגה',
            Id: 'expirationDate',
            Type: 'FieldDateHour',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        RemainingUses: {
            Order: 7,
            Display: 'מלאי',
            Id: 'remainingUses',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 6,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    exerciseAnswers: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        UserName: {
            Order: 2,
            Display: 'שם התלמיד',
            Id: 'userName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ExerciseName: {
            Order: 3,
            Display: 'שם התרגול',
            Id: 'exerciseName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ReportUrl: {
            Order: 4,
            Display: 'כניסה לדו"ח תרגול',
            Id: 'reportUrl',
            Type: 'FieldCustomerLink',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Grade: {
            Order: 4.5,
            Display: 'ציון',
            Id: 'grade',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        MadadTakinEditor: {
            Order: 5,
            Display: 'טווח ציונים תקין',
            Id: 'madadTakinEditor',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        StarPercent: {
            Order: 6,
            Display: 'אחוז כוכביות',
            Id: 'starPercent',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        StarGrade: {
            Order: 7,
            Display: 'ציון כוכביות',
            Id: 'starGrade',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        GrayPercent: {
            Order: 8,
            Display: 'אחוז אפורות',
            Id: 'grayPercent',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        GrayGrade: {
            Order: 9,
            Display: 'ציון אפורות',
            Id: 'grayGrade',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Ahuzon: {
            Order: 10,
            Display: 'אחוזון',
            Id: 'ahuzon',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        EndDateTime: {
            Order: 11,
            Display: 'תאריך ביצוע',
            Id: 'endDateTime',
            Type: 'FieldDateHour',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        WasResearched: {
            Order: 12,
            Display: 'האם תוחקר',
            Id: 'wasResearched',
            Type: 'FieldExerciseResearch',
        },
        ExerciseQuestionsLink: {
            Order: 13,
            Display: 'קישור לשאלון',
            Id: 'exerciseQuestion',
            Type: 'FieldExerciseQuestions',
        },
        FieldExerciseAnswers: {
            Order: 14,
            Display: 'קישור לפתרון',
            Id: 'exerciseAnswer',
            Type: 'FieldExerciseAnswers',
        },
        FieldExerciseReset: {
            Order: 15,
            Display: 'איפוס תרגול',
            Id: 'exerciseReset',
            Type: 'FieldExerciseReset',
        },
    },
    videos: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonId: {
            Order: 2,
            Display: 'מספר שיעור',
            Id: 'lessonId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        VideoId: {
            Order: 3,
            Display: 'מספר וידאו',
            Id: 'videoId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SortOrder: {
            Order: 4,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 5,
            Display: 'שם',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Link: {
            Order: 6,
            Display: 'קישור',
            Id: 'link',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Link1: {
            Order: 7,
            Display: 'קישור',
            Id: 'link1',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonCategory: {
            Order: 8,
            Display: 'קטגוריה',
            Id: 'lessonCategoryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        VideoType: {
            Order: 9,
            Display: 'סוג',
            Id: 'videoTypeName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LawCollectionName: {
            Order: 8,
            Display: 'מקבץ חקיקה',
            Id: 'lawCollectionName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        VerdictCollectionName: {
            Order: 9,
            Display: 'מקבץ פסיקה',
            Id: 'verdictCollectionName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Category: {
            Order: 10,
            Display: 'קטגוריה',
            Id: 'categoryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        VideoCategory: {
            Order: 11,
            Display: 'נושא',
            Id: 'videoCategory',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 12,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        IsInteractiveVideo: {
            Order: 13,
            Display: 'אינטרקטיבי',
            Id: 'isInteractiveVideo',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    exercises: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonId: {
            Order: 2,
            Display: 'מספר יחידת לימוד',
            Id: 'lessonId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 3,
            Display: 'יחידת לימוד',
            Id: 'lessonName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ExerciseName: {
            Order: 4,
            Display: 'שם התרגול',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        CategoryName: {
            Order: 5,
            Display: 'נושא',
            Id: 'categoryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SortOrder: {
            Order: 6,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 7,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Entity: {
            Order: 8,
            Display: 'ישות',
            Id: 'exercise',
            Type: 'FieldEntity',
            EntityType: 'תרגול'
        }
    },
    laws: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonId: {
            Order: 2,
            Display: 'מספר שיעור',
            Id: 'lessonId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SortOrder: {
            Order: 3,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 4,
            Display: 'שם',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LawCollectionName: {
            Order: 5,
            Display: 'מקבץ',
            Id: 'lawCollectionName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Category: {
            Order: 6,
            Display: 'קטגוריה',
            Id: 'exerciseCategoryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 7,
            Display: 'סטטוס',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    users: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        PrivateName: {
            Order: 2,
            Display: 'שם פרטי',
            Id: 'privateName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LastName: {
            Order: 3,
            Display: 'שם משפחה',
            Id: 'lastName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        UserName: {
            Order: 4,
            Display: 'דוא"ל',
            Id: 'userName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Phone: {
            Order: 5,
            Display: 'טלפון',
            Id: 'phone1',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Address: {
            Order: 6,
            Display: 'כתובת',
            Id: 'address',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 7,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        CreateDate: {
            Order: 7,
            Display: 'תאריך יצירה',
            Id: 'createdate',
            Type: 'FieldDateHour',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        UserTypeId: {
            Order: 8,
            Display: 'גישה לעורך',
            Id: 'isAdminLinkAllowed',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Entity: {
            Order: 9,
            Display: 'ישות',
            Id: 'user',
            Type: 'FieldEntity',
            EntityType: 'סטודנט'
        }
    },
    verdicts: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonId: {
            Order: 2,
            Display: 'מספר שיעור',
            Id: 'lessonId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SortOrder: {
            Order: 3,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 4,
            Display: 'שם',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        VerdictSummaryName: {
            Order: 5,
            Display: 'תקציר',
            Id: 'verdictSummaryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LawCollectionName: {
            Order: 6,
            Display: 'חוק',
            Id: 'lawName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Category: {
            Order: 7,
            Display: 'קטגוריה',
            Id: 'exerciseCategoryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 8,
            Display: 'סטטוס',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    questions: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Question: {
            Order: 2,
            Display: 'שאלה',
            Id: 'question',
            Type: 'FieldHtml',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        EditorNotes: {
            Order: 3,
            Display: 'הערות עורך',
            Id: 'editorNotes',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Entity: {
            Order: 4,
            Display: 'ישות',
            Id: 'question',
            Type: 'FieldEntity',
            EntityType: 'שאלה'
        }
    },
    requests: {
        Id: {
            Order: 1,
            Display: 'מספר פנייה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Fullname: {
            Order: 2,
            Display: 'שם תלמיד',
            Id: 'fullname',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SourceType: {
            Order: 3,
            Display: 'מקור פנייה',
            Id: 'sentFromPage',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Subject: {
            Order: 4,
            Display: 'נושא',
            Id: 'messagesInCourseType',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Description: {
            Order: 5,
            Display: 'תיאור',
            Id: 'description',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SubSubject: {
            Order: 6,
            Display: 'תת נושא',
            Id: 'questionSubject',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        CreateDate: {
            Order: 7,
            Display: 'תאריך פתיחה',
            Id: 'createdDate',
            SortField: 'Type',
            Type: 'FieldDateHour',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 8,
            Display: 'סטטוס',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldRequestStatus',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Chat: {
            Order: 9,
            Display: "פתיחת צ'אט",
            Id: "icon",
            Type: 'FieldRequestOpenChat'
        }
    },
    groups: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 2,
            Display: 'שם',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        CreateDate: {
            Order: 3,
            Display: 'תאריך יצירה',
            Id: 'createDate',
            Type: 'FieldDateHour',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Entity: {
            Order: 4,
            Display: 'ישות',
            Id: 'group',
            Type: 'FieldEntity',
            EntityType: 'קבוצה'
        }
    },
    expoChapters: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        DisplayName: {
            Order: 2,
            Display: 'שם פרק',
            Id: 'displayName',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        UnitDisplayNameText: {
            Order: 3,
            Display: 'כותרת תווית יחידה',
            Id: 'unitDisplayNameText',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        IntroText: {
            Order: 4,
            Display: 'פתיח',
            Id: 'introText',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Status: {
            Order: 5,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'מזהה הלקוח',
        },
        SortOrder: {
            Order: 6,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        LessonExerciseId: {
            Order: 7,
            Display: 'תרגול מקושר',
            Id: 'lessonExerciseId',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        ExpoType: {
            Order: 8,
            Display: 'סוג תרגול',
            Id: 'expoType',
            Type: 'FieldExpoType',
            Tooltip: 'מזהה הלקוח',
        },
        ExerciseCategoryName: {
            Order: 9,
            Display: 'קטגוריה',
            Id: 'exerciseCategoryName',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        GroupName: {
            Order: 10,
            Display: 'קבוצה',
            Id: 'groupName',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Entity: {
            Order: 11,
            Display: 'ישות',
            Id: 'expoChapter',
            Type: 'FieldEntity',
            EntityType: 'פרק'
        }
    },
    expoChapterQuestions: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        UnitDisplayName: {
            Order: 2,
            Display: 'תווית יחידה',
            Id: 'unitDisplayName',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Question: {
            Order: 3,
            Display: 'שאלה',
            Id: 'question',
            Type: 'FieldHtml',
            Tooltip: 'מזהה הלקוח',
        },
        Answer: {
            Order: 4,
            Display: 'תשובה נכונה',
            Id: 'answer',
            Type: 'FieldBoolean',
            Tooltip: 'מזהה הלקוח',
        },
        DetailedAnswer: {
            Order: 5,
            Display: 'פתרון',
            Id: 'detailedAnswer',
            Type: 'FieldHtml',
            Tooltip: 'מזהה הלקוח',
        },
        Status: {
            Order: 6,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'מזהה הלקוח',
        },
        Count: {
            Order: 7,
            Display: 'מספר עונים',
            Id: 'count',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        CorrectPercent: {
            Order: 8,
            Display: '% מענה נכונה',
            Id: 'percent',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
    },
    lecturesRegions: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Name: {
            Order: 2,
            Display: 'שם',
            Id: 'name',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Description: {
            Order: 2,
            Display: 'תיאור',
            Id: 'description',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 4,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SortOrder: {
            Order: 5,
            Display: 'סדר',
            Id: 'sortOrder',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    lecturesHalls: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Name: {
            Order: 2,
            Display: 'שם',
            Id: 'name',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Address: {
            Order: 3,
            Display: 'כתובת',
            Id: 'address',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        City: {
            Order: 4,
            Display: 'עיר',
            Id: 'city',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        LecturesRegionName: {
            Order: 5,
            Display: 'אזור',
            Id: 'lecturesRegionName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 5,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    lecturesTeachers: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        FullName: {
            Order: 2,
            Display: 'שם מלא',
            Id: 'fullName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        LecturesCount: {
            Order: 3,
            Display: 'הרצאות בקורס',
            Id: 'lecturesCount',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 4,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    lecturesFeedbackStatements: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Name: {
            Order: 2,
            Display: 'שם',
            Id: 'name',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Description: {
            Order: 3,
            Display: 'תיאור',
            Id: 'description',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        TypeName: {
            Order: 4,
            Display: 'סוג',
            Id: 'typeName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 5,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SortOrder: {
            Order: 6,
            Display: 'סדר',
            Id: 'sortOrder',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    lectures: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        StartTime: {
            Order: 2,
            Display: 'בתאריך',
            Id: 'startTime',
            SortField: 'Type',
            Type: 'FieldDateHour',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Day: {
            Order: 3,
            Display: 'יום',
            Id: 'day',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SubjectName: {
            Order: 4,
            Display: 'נושא',
            Id: 'subjectName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        TrackName: {
            Order: 5,
            Display: 'מסלול',
            Id: 'trackName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        TeacherName: {
            Order: 6,
            Display: 'מרצה',
            Id: 'teacherName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Address: {
            Order: 7,
            Display: 'כתובת',
            Id: 'address',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        HallName: {
            Order: 8,
            Display: 'אולם',
            Id: 'hallName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Count: {
            Order: 9,
            Display: 'רשומים',
            Id: 'count',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 10,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    lecturesLearningTracks: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Name: {
            Order: 2,
            Display: 'שם',
            Id: 'name',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        EditorNote: {
            Order: 3,
            Display: 'הערה פנימית',
            Id: 'editorNote',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        UserNote: {
            Order: 4,
            Display: 'הערה לתלמיד',
            Id: 'userNote',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        AvailableFrom: {
            Order: 5,
            Display: 'זמין מ',
            Id: 'availableFrom',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך יצירת הלינק',
        },
        AvailableUntil: {
            Order: 6,
            Display: 'זמין עד',
            Id: 'availableUntil',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך יצירת הלינק',
        },
        Status: {
            Order: 7,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SortOrder: {
            Order: 8,
            Display: 'סדר',
            Id: 'sortOrder',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    paidReports: {
        PayId: {
            Display: 'מזהה תשלום',
            Id: 'Address',
            Type: 'FieldStandard',
            Tooltip: 'המיקום בו נרשם הדוח ע"פ הפקח',
            Sortable: false,
        },
    },
    customers: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'ClientId',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Name: {
            Order: 2.5,
            Display: 'שם',
            Id: 'Name',
            Type: 'FieldStandard',
            Tooltip: 'שם הלקוח',
        },
        CreateDate: {
            Display: 'ת. יצירה',
            Id: 'ClientDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך יצירת הלינק',
        },
        ExpirationDate: {
            Display: 'ת. פקיעה',
            Id: 'ExpirationDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך פקיעת הלינק',
        },
        FirstAccessDate: {
            Display: 'ת. צפיה',
            Id: 'FirstAccessDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו הלינק נצפה לראשונה',
        },
        Client: {
            Display: 'שם לקוח',
            Id: 'Client',
            Type: 'FieldStandard',
            Tooltip: 'שם הלקוח',
            map: (value) => value.Name,
        },
        IdNumber: {
            Order: 2,
            Display: 'ח.פ',
            Id: 'IdNumber',
            Type: 'FieldStandard',
            Tooltip: 'חברה פרטית',
            Width: 100,
        },
        HasTransferForm: {
            Display: 'תבנית הסבה',
            Id: 'HasTransferForm',
            Type: 'FieldBoolean',
            Tooltip: '',
            align: 'center',
            centeredHeader: true,
        },
        HasOpeningPage: {
            Order: 3,
            Display: 'דף ראשון בהסכם',
            Id: 'HasOpeningPage',
            Type: 'FieldBoolean',
            Tooltip: '',
            align: 'center',
            centeredHeader: true,
        },
        HasGeneralContractForm: {
            Order: 4,
            Display: 'הצהרת חוכר כללית',
            Id: 'HasGeneralContractForm',
            Type: 'FieldBoolean',
            Tooltip: '',
            align: 'center',
            centeredHeader: true,
        },
        HasDriversLicense: {
            Order: 5,
            Display: 'תצלום רישיון נהיגה',
            Id: 'HasDriversLicense',
            Type: 'FieldBoolean',
            Tooltip: '',
            align: 'center',
            centeredHeader: true,
        },
        Fee: {
            Order: 6,
            Display: 'דמי ניהול',
            Id: 'Fee',
            Type: 'FieldStandard',
            Tooltip: 'דמי ניהול של החברה הגובה',
            align: 'center',
            centeredHeader: true,
        },
        InternalClientId: {
            Order: 7,
            Display: 'מזהה פנימי',
            Id: 'InternalClientId',
            Type: 'FieldStandard',
            Tooltip: 'מזהה פנימי של הלקוח אצל החברה',
            Width: 100,
        },
        Email: {
            Order: 8,
            Display: 'אימייל',
            Id: 'Email',
            Type: 'FieldStandard',
            Tooltip: 'כתובת מייל למשלוח לינק',
            Width: 200,
        },
        AllowsApproval: {
            Order: 9,
            Display: 'הרשאה לאישור תשלום',
            Id: 'AllowsApproval',
            Type: 'FieldBoolean',
            Tooltip: 'האם הלקוח רשאי לאשר דוחות לתשלום',
            align: 'center',
            centeredHeader: true,
            Width: 150,
        },
        AllowContractHtmlAggregation: {
            Order: 10,
            Display: 'תמיכה בחוזים ישנים',
            Id: 'AllowContractHtmlAggregation',
            Type: 'FieldBoolean',
            Tooltip: 'הגדרה פנימית של Betterway',
            align: 'center',
            centeredHeader: true,
        },
        ForbidTransfer: {
            Order: 11,
            Display: 'החרג הסבה ללקוח זה',
            Id: 'ForbidTransfer',
            Type: 'FieldBoolean',
            Tooltip: 'הסבות עבור לקוח זה לא יבוצעו',
            align: 'center',
            centeredHeader: true,
        },
        CustomerLink: {
            Display: 'לינק',
            Id: 'ShortToken',
            Type: 'FieldCustomerLink',
            Tooltip: 'מעבר לדף לקוח',
            centeredHeader: true,
        },
    },
    tollRoads: {
        AccountNumber: {
            Order: 1,
            Display: 'מספר חשבון',
            Id: 'AccountNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר החשבון בכביש האגרה',
            Sortable: false,
        },
        VehicleType: {
            Order: 2,
            Display: 'סוג הרכב',
            Id: 'VehicleType',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב',
            Sortable: false,
        },
        InvoiceNumber: {
            Order: 3,
            Display: 'מספר חשבונית',
            Id: 'InvoiceNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר החשבונית בחברה הגובה',
            Sortable: false,
        },
        PlateNumber: {
            Order: 4,
            Display: 'לוחית רישוי',
            Id: 'Vehicle',
            Type: 'FieldVehicle',
            map: (i) => i.Name,
            mapItem: (i) => i.Vehicle,
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LinkedClient: {
            Order: 5,
            Display: 'לקוח',
            Id: 'LinkedClient',
            Type: 'FieldEnum',
            Tooltip: 'הלקוח המקושר לנסיעה זו',
            Sortable: false,
            Namespace: 'customers',
        },
        DateTaken: {
            Order: 6,
            Display: 'ת. נסיעה',
            Id: 'DateTaken',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך הנסיעה בכביש האגרה',
            Sortable: false,
        },
        Amount: {
            Order: 7,
            Display: 'סכום לתשלום ללא מע"מ',
            Id: 'Amount',
            Type: 'FieldCurrency',
            Tooltip: 'הסכום שיש לשלם בעבור הנסיעה',
            Sortable: false,
        },
        EntryPoint: {
            Order: 8,
            Display: 'נקודת כניסה',
            Id: 'EntryPoint',
            Type: 'FieldStandard',
            Tooltip: 'המקטע הראשון אליו נכנס הרכב',
            Sortable: false,
        },
        ExitPoint: {
            Order: 9,
            Display: 'נקודת יציאה',
            Id: 'ExitPoint',
            Type: 'FieldStandard',
            Tooltip: 'המקטע האחרון ממנו יצא הרכב',
            Sortable: false,
        },
        SegmentCount: {
            Order: 10,
            Display: 'מספר מקטעים',
            Id: 'SegmentCount',
            Type: 'FieldStandard',
            Tooltip: 'מספר המקטעים שהרכב עבר באותה נסיעה',
            Sortable: false,
        },
        TollRoadStatus: {
            Order: 11,
            Display: 'סטטוס חשבונית',
            Id: 'TollRoadStatus',
            Type: 'FieldStandard',
            Tooltip: 'סטטוס החשבונית כפי שמופיע בחברת כבישי האגרה',
            map: (f) => f.Name,
            Sortable: false,
        },
        VerificationDate: {
            Order: 12,
            Display: 'ת. אימות',
            Id: 'VerificationDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך אימות הנסיעה במערכת BetterWay',
            Sortable: false,
        },
        LinkedDriver: {
            Order: 13,
            Display: 'נהג',
            Id: 'LinkedDriver',
            Type: 'FieldDriver',
            Tooltip: 'הנהג ברכב בזמן הנסיעה',
            Sortable: false,
        },
    },
    drivers: {
        IdNumber: {
            Order: 1,
            Display: 'ת.ז',
            Id: 'IdNumber',
            Type: 'FieldStandard',
            Tooltip: 'ת.ז של הנהג',
            Sortable: false,
        },
        Name: {
            Order: 2,
            Display: 'שם מלא',
            Id: 'Name',
            Type: 'FieldStandard',
            Tooltip: 'שם מלא של הנהג',
            Sortable: false,
        },
        PhoneNumber: {
            Order: 3,
            Display: 'מספר טלפון',
            Id: 'PhoneNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר הטלפון של הנהג',
            Sortable: false,
        },
        Email: {
            Order: 3.5,
            Display: 'מייל',
            Id: 'Email',
            Type: 'FieldStandard',
            Tooltip: 'מייל של הנהג',
            Sortable: false,
        },
        City: {
            Order: 4,
            Display: 'עיר',
            Id: 'City',
            Type: 'FieldStandard',
            Tooltip: 'עיר',
            Sortable: false,
        },
        Street: {
            Order: 5,
            Display: 'רחוב',
            Id: 'Street',
            Type: 'FieldStandard',
            Tooltip: 'רחוב',
            Sortable: false,
        },
        HouseNumber: {
            Order: 6,
            Display: 'מספר בית',
            Id: 'HouseNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר בית',
            Sortable: false,
        },
        ZipCode: {
            Order: 7,
            Display: 'מיקוד',
            Id: 'ZipCode',
            Type: 'FieldStandard',
            Tooltip: 'מיקוד',
            Sortable: false,
        },
        DriverNumber: {
            Order: 8,
            Display: 'מספר נהג במערכת CRM',
            Id: 'DriverNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר הנהג במערכת הפנימית של הארגון שלך',
            Sortable: false,
        },
        TrackerDriverId: {
            Order: 9,
            Display: 'קוד מערכת ניתור',
            Id: 'TrackerDriverId',
            Type: 'FieldStandard',
            Tooltip: 'קוד מערכת ניתור',
            Sortable: false,
        },
        LicenseNumber: {
            Order: 10,
            Display: 'מספר רשיון נהיגה',
            Id: 'LicenseNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר רשיון נהיגה',
            Sortable: false,
        },
        HasDriversLicense: {
            Order: 10,
            Display: 'קיים תצלום רשיון נהיגה',
            Id: 'HasDriversLicense',
            Type: 'FieldBoolean',
            Tooltip: 'קיים תצלום רשיון נהיגה',
            Sortable: false,
        },
        HasTransferForm: {
            Order: 11,
            Display: 'קיים תצהיר',
            Id: 'HasTransferForm',
            Type: 'FieldBoolean',
            Tooltip: 'קיים תצהיר',
            Sortable: false,
        },
        IsManager: {
            Order: 12,
            Display: 'נהג מנהל',
            Id: 'IsManager',
            Type: 'FieldBoolean',
            Tooltip: 'נהג מנהל',
            Sortable: false,
        },
        IsActive: {
            Order: 13,
            Display: 'פעיל',
            Id: 'IsActive',
            Type: 'FieldBoolean',
            Tooltip: 'נהג מנהל',
            Sortable: false,
        },
    },
    tasks: {
        AssignedUserName: {
            Order: 3,
            Display: 'משתמש משוייך',
            Id: 'AssignedUserName',
            Type: 'FieldStandard',
            Tooltip: 'המשתמש אליו משוייכת המשימה',
            Sortable: false,
            SortField: 'AssignedTo',
        },
        AssignedClientProfile: {
            Order: 3.1,
            Display: 'לקוח משוייך',
            Id: 'ClientProfile',
            Type: 'FieldEnum',
            onClick: () => { },
            Tooltip: 'הלקוח אליו משוייכת המשימה',
            Sortable: false,
            Namespace: 'clients',
            SortField: 'AssignedTo',
        },
        AssignedOwnerProfile: {
            Order: 3.2,
            Display: 'בעלים משוייך',
            Id: 'OwnerProfile',
            Type: 'FieldEnum',
            onClick: () => { },
            Tooltip: 'הבעלים אליו משוייכת המשימה',
            Sortable: false,
            SortField: 'AssignedTo',
        },
        AssignedDriverProfile: {
            Order: 3.3,
            Display: 'נהג משוייך',
            Id: 'DriverProfile',
            Type: 'FieldEnum',
            onClick: () => { },
            Tooltip: 'הנהג אליו משוייכת המשימה',
            Sortable: false,
            Namespace: 'drivers',
            SortField: 'AssignedTo',
        },
        DueDate: {
            Order: 4,
            Display: 'ת. יעד',
            Id: 'DueDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך היעד בו המשימה צריכה להסתיים',
            Sortable: false,
        },
        EmailReminderDate: {
            Order: 8,
            Display: 'ת. תזכורת',
            Id: 'EmailReminderDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו נקבעה תזכורת למשימה',
        },
        Priority: {
            Order: 5,
            Display: 'דחיפות',
            Id: 'Priority',
            Type: 'FieldPriority',
            Tooltip: 'דחיפות המשימה',
            Sortable: false,
            align: 'center',
            centeredHeader: true,
        },
        IsOverdue: {
            Order: 9,
            Display: 'חריגה',
            Id: 'IsOverdue',
            Type: 'FieldBoolean',
            Tooltip: 'האם תאריך יעד המשימה עבר',
            align: 'center',
            centeredHeader: true,
        },
        IsComplete: {
            Display: 'הושלם',
            Id: 'IsComplete',
            Type: 'FieldIsComplete',
            Tooltip: 'סטטוס המשימה',
            Order: 0,
            align: 'center',
            centeredHeader: true,
        },
        EntityType: {
            Order: 0.5,
            Display: 'סוג פריט',
            Id: 'EntityType',
            Type: 'FieldEntityType',
            Tooltip: 'סוג הישות',
            align: 'center',
            centeredHeader: true,
        },
        CompletionDate: {
            Order: 8,
            Display: 'ת. ביצוע',
            Id: 'CompletionDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך ביצוע המשימה',
            Sortable: false,
        },
        CreateDate: {
            Order: 6,
            Display: 'ת. יצירה',
            Id: 'CreateDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך יצירת המשימה',
            Sortable: false,
        },
        CreatorName: {
            Order: 7,
            Display: 'שם היוצר',
            Id: 'CreatorName',
            Type: 'FieldStandard',
            Tooltip: 'שם יוצר המשימה',
        },
        Title: {
            Order: 1,
            Display: 'כותרת',
            Id: 'Title',
            Type: 'FieldStandard',
            Tooltip: 'כותרת המשימה',
            Sortable: false,
        },
        Body: {
            Order: 2,
            Display: 'תוכן',
            Id: 'Body',
            Type: 'FieldHtml',
            Tooltip: 'תוכן המשימה',
            Sortable: false,
        },
    },
    accidents: {
        CreateDate: {
            Order: 1,
            Display: 'ת. יצירה',
            Id: 'CreateDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך יצירת התאונה',
            centeredHeader: true,
            align: 'center',
        },
        VehiclePlateNumber: {
            Order: 2,
            Display: 'לוחית רישוי',
            Id: 'PlateNumber',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            centeredHeader: true,
            align: 'center',
        },
        AccidentType: {
            Order: 3,
            Display: 'סוג',
            Id: 'AccidentType',
            Type: 'FieldAccidentType',
            Tooltip: 'סוג הדיווח',
            centeredHeader: true,
            align: 'center',
        },
        AccidentDate: {
            Order: 4,
            Display: 'ת. האירוע',
            Id: 'Date',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך האירוע',
            centeredHeader: true,
            align: 'center',
        },
        Description: {
            Order: 5,
            Display: 'תיאור המקרה',
            Id: 'Description',
            Type: 'FieldStandard',
            Tooltip: 'תיאור המקרה',
        },
        FieldPdfStatus: {
            Order: 6,
            Display: 'סטטוס',
            Id: 'PdfStatus',
            Type: 'FieldAccidentPdfStatus',
            Tooltip: 'סטטוס הטופס',
            centeredHeader: true,
            align: 'center',
        },
        Document: {
            Order: 7,
            Display: 'טופס',
            Id: 'PdfUrl',
            Type: 'FieldAccidentPdf',
            Tooltip: 'טופס תאונה',
            centeredHeader: true,
            align: 'center',
        },
    },
    municipalities: {
        Id: {
            Order: 0,
            Display: 'מזהה',
            Id: 'Id',
            Type: 'FieldNumeric',
            Tooltip: 'מזהה שורה',
            AdminOnly: true,
            Sortable: false,
        },
        Municipality: {
            Display: 'עיריה',
            Id: 'Municipality',
            Type: 'FieldStandard',
            Tooltip: 'הרשות שמטעמה נתקבל הדוח',
            Sortable: false,
            AdminOnly: true,
            Order: 1,
        },

    },
};

export default {
    list: (namespace) => ({ ...fields[namespace] }),
};
