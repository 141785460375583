<template>
    <div class="Field__Component">
        <div v-html="value"></div>
    </div>
</template>

<script>
export default {
    name: 'FieldHtml',
    props: ['value'],
};
</script>

<style scoped>

</style>
