var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "div",
      {
        staticClass: "red",
        staticStyle: { width: "30px", height: "20px" },
        style: { backgroundColor: _vm.getBackgroundColor() }
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }