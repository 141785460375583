/* eslint-disable */

// const addStyles = (win, styles) => {
//   styles.forEach(style => {
//     const link = win.document.createElement("link");
//     link.setAttribute("rel", "stylesheet");
//     link.setAttribute("type", "text/css");
//     link.setAttribute("href", style);
//     win.document.getElementsByTagName("head")[0].appendChild(link);
//   });
// };

export default {
  printHtml: (html, el, localOptions, cb = () => true) => {
    const defaultName = "_blank";
    const defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];
    const defaultStyles = [];
    const defaultTimeout = 1000;
    const defaultAutoClose = true;
    const defaultWindowTitle = window.document.title;
    let {
      name = defaultName,
      specs = defaultSpecs,
      styles = defaultStyles,
      timeout = defaultTimeout,
      autoClose = defaultAutoClose,
      windowTitle = defaultWindowTitle
    } = {};

    if (localOptions?.name) name = localOptions.name;
    if (localOptions?.specs) specs = localOptions.specs;
    if (localOptions?.styles) styles = localOptions.styles;
    if (localOptions?.timeout) timeout = localOptions.timeout;
    if (localOptions?.autoClose) autoClose = localOptions.autoClose;
    if (localOptions?.windowTitle) windowTitle = localOptions.windowTitle;

    specs = specs.length ? specs.join(",") : "";

    let element = el;
    if (typeof el === "string" || el instanceof String) {
      element = window.document.getElementById(el);
    } else {
      element = document.createElement("div");
      element.setAttribute("id", "vue-printer-plugin");
      document.body.appendChild(element);
    }
    if (!element) {
      alert(`Element to print #${el} not found!`);
      return;
    }
    var iframe = document.createElement("iframe");
    iframe.setAttribute("id", "printf");
    const blob = new Blob([html], { type: "text/html" });
    document.body.appendChild(iframe);
    window.document.querySelectorAll("iframe#printf")[0].src = window.URL.createObjectURL(blob);
    iframe.onload = () => {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      setTimeout(() => {
        iframe.remove();
        document.body.querySelector('.table-wrapper').scrollTop = el
      }, 0);
    };

    // const url = '';
    // const win = window.open(url, name, specs);
    // if(!win) {
    //     cb(false);
    //     element.remove();
    // }
    // win.document.write(`
    // <html>
    //     <head>
    //     <title>${windowTitle}</title>
    //     </head>
    //     <body>
    //     ${html}
    //     </body>
    // </html>
    // `);

    // addStyles(win, styles);

    // const promise = new Promise(resolve => {
    //   setTimeout(() => {
    //     // win.focus();
    //     // win.print();
    //     document.getElementById("printf").contentWindow.focus();
    //     document.getElementById("printf").contentWindow.print();
    //     // autoClose && win.document.close();
    //     // autoClose && win.close();
    //     element.remove();
        if (cb) cb();
    //     resolve();
    //   }, timeout);
    // });

    return cb ? true : promise;
  }
};
