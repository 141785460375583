<template>
    <div class="Field__Component">
        <span>{{label}}</span>
    </div>
</template>

<script>
export default {
    name: 'FieldAccidentType',
    props: ['value'],
    computed: {
        label() {
            switch (this.value) {
                case 0:
                    return 'נזק לרכוש';
                case 1:
                    return 'נזק עצמי';
                case 2:
                    return 'אני הפוגע';
                case 3:
                    return 'אני הנפגע';
                case 4:
                    return 'אני הפוגע';
                default:
                    return '-';
            }
        },
    },
};
</script>

<style>

</style>
