var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Field__Component",
      class: { paid: _vm.isPaid, "double-fine": _vm.isDobuleFine }
    },
    _vm._l(_vm.value, function(alert, index) {
      return _c("div", { key: index }, [_vm._v(_vm._s(alert.Name))])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }