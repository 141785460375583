var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.open
      ? _c(
          "div",
          {
            staticClass: "Overlay__Component",
            class: { transparent: _vm.transparent }
          },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }