<template>
    <div class="Field__Component">
        <div>
            <slot>
                <router-link v-if="field.isAdmin" @click.stop="onClick" :to="`/admin/vehicles/${item.Id}`">
                    {{value}}
                </router-link>
                <router-link v-else @click.stop="onClick" :to="`/vehicles/${item.Id}`">
                    {{value}}
                </router-link>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FieldVehicle',
    props: ['value', 'item', 'field'],
    computed: {
        IsVehicleMine() {
            return this.item.IsVehicleMine;
        },
    },
    methods: {
        onClick(event) {
            if (this.IsVehicleMine) {
                console.log('yes');
            } else {
                event.prevemtDefault();
            }
        },
    },
};
</script>

<style>

</style>
