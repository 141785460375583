<template>
    <div class="Field__Component">
        <label>
            <span><slot></slot></span>
            <input type='text' name=''
            :class="{'vs--has-value': value}"
             @input="$emit('input', $event.target.value)" :value="value" />
        </label>
    </div>
</template>

<script>

export default {
    name: 'FilterString',
    props: ['value'],

};
</script>
