<template>
    <div class="Field__Component">
        <div :class="`Entity__Type Entity__Type-${value}`">{{entityDisplayName}}</div>
    </div>
</template>

<script>
export default {
    name: 'FieldEntityType',
    props: ['value'],
    computed: {
        entityDisplayName() {
            switch (this.value) {
                case 1:
                    return 'דוח';
                case 2:
                    return 'רכב';
                case 3:
                    return 'פרופיל';
                case 4:
                    return 'תאונה';
                case 5:
                    return 'הסבה';
                case 6:
                    return 'הסבת נהג';
                case 7:
                    return 'חוזה רכב';
                case 8:
                    return 'חוזה נהג';
                case 9:
                    return 'לקוח';
                case 10:
                    return 'נהג';
                default:
                    return null;
            }
        },
    },
};
</script>

<style scoped>
    .Entity__Type {
        font-weight: 500;
    }
    .Entity__Type-1 {
        color: #0fa2c1;
    }
    .Entity__Type-2 {
        color: #de8708;
    }
    .Entity__Type-5, .Entity__Type-6 {
        color: #9c27b0;
    }
    .Entity__Type-9 {
        color: #3f9442;
    }
</style>
