<template>
    <div class="Field__Component" :class="{[align]: align}">
        <div>{{date}}</div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'FieldDateHour',
    props: ['value', 'align'],
    computed: {
        date() {
            if (this.value) {
                return moment(this.value).format('D.MM.YYYY HH:mm');
            }
            return null;
        },
    },
};
</script>

<style scoped lang="scss">
    .Field__Component {
        direction: ltr;
        text-align: right;

        &.right {
            text-align: right;
        }
        &.left {
            text-align: left;
        }
        &.center {
            text-align: center;
        }
    }
</style>
