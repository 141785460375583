<template>
    <div class="Field__Component">
        <div>{{value ? `₪${value}`: ''}}</div>
    </div>
</template>

<script>
export default {
    name: 'FieldCurrency',
    props: ['value'],
};
</script>

<style scoped lang="scss">
    .Field__Component {
        text-align: center;
    }
</style>
