<template>
    <div class="Field__Component">
        <div><slot>
            <router-link v-if="field.isAdmin" :to="`/admin/reports/${item.Id}`">{{value}}</router-link>
            <router-link v-else :to="`/reports/${item.Id}`">{{value}}</router-link>
        </slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldLink',
    props: ['value', 'item', 'field'],
};
</script>

<style scoped>

</style>
