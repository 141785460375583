var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Field__Component" },
    [
      _vm.isLoading
        ? [
            _c(
              "div",
              { staticClass: "loading" },
              [
                _c("b-loading", {
                  attrs: { "is-full-page": false, "can-cancel": false },
                  model: {
                    value: _vm.isLoading,
                    callback: function($$v) {
                      _vm.isLoading = $$v
                    },
                    expression: "isLoading"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "span",
            {
              staticStyle: { padding: "5px" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.setSortOrder(_vm.item, _vm.data.index, 1)
                }
              }
            },
            [
              _c(
                "b-icon",
                {
                  staticClass: "sort__order__button",
                  attrs: { size: "is-small", icon: "arrow-down" }
                },
                [_vm._v("+")]
              )
            ],
            1
          ),
          _vm._t("default", [_vm._v(_vm._s(_vm.value))]),
          _c(
            "span",
            {
              staticStyle: { padding: "5px" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.setSortOrder(_vm.item, _vm.data.index, -1)
                }
              }
            },
            [
              _c(
                "b-icon",
                {
                  staticClass: "sort__order__button",
                  attrs: { size: "is-small", icon: "arrow-up" }
                },
                [_vm._v("-")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }