var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Field__Component", class: { complete: _vm.model } },
    [
      _c(
        "div",
        [
          _vm._t("default", [
            _c(
              "button",
              { on: { click: _vm.complete } },
              [
                _c("b-icon", {
                  attrs: {
                    icon: _vm.model ? "check-circle" : "check-circle-outline"
                  }
                })
              ],
              1
            )
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }