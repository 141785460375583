<template>
    <div class="Field__Component">
        <div @click.stop="resetExercise(item)">
            <slot>
                <b-button v-model="item.wasResearched" label="" type="is-danger" class="">איפוס</b-button>
            </slot>
        </div>
    </div>
</template>

<script>
import LessonService from "@/services/LessonService";
import {
    LoadingProgrammatic as Loading,
} from 'buefy';

export default {
    name: 'FieldStandard',
    props: ['value', 'item'],
    computed: {
        courseId() {
            return this.$store.state.auth.course?.id
        }
    },
    methods: {
        resetExercise(item) {
            this.$buefy.dialog.confirm({
                title: 'איפוס תרגול',
                message: 'האם לאפס את התרגול למשתמש?',
                confirmText: 'כן',
                cancelText: 'לא',
                type: 'is-danger',
                closeOnConfirm: false,
                hasIcon: true,
                onConfirm: (a, instance) => {
                    const loading = Loading.open();
                    LessonService.resetUserExercise(item.exerciseId, item.userId).then(() => {
                        instance.close();
                        this.$buefy.toast.open({
                            type: "is-success",
                            message: `התרגול אופס בהצלחה!`,
                            duration: 4000,
                        });
                    }).catch(() => {
                        this.$buefy.toast.open({
                            type: "is-danger",
                            message: `הפעולה נכשלה!`,
                            duration: 8000,
                        });
                    }).finally(() => {
                        loading.close();
                    });
                },
            });
        }
    }
};
</script>

<style scoped></style>
course/31/exercisePrint/3642