<template>
    <div class="Field__Component">
        <b-button type="is-primary" @click="show">צפה</b-button>
    </div>
</template>

<script>
export default {
    name: 'FieldAccidentType',
    props: ['value'],
    methods: {
        show() {
            window.open(this.value, '_blank');
        },
    },
};
</script>

<style>

</style>
