<template>
  <div class="Field__Component">
    <label for="">
      <span><slot></slot></span>
      <v-select
        dir="rtl"
        :options="data"
        :clearable="clearable"
        @search="fetchOptions"
        :placeholder="'בחר ערך מהרשימה'"
        label="Text"
        @input="setSelected"
        :value="value"
        :filter="filter"
      >
        <!-- :map-keydown="handlers" -->
        <div slot="no-options">לא נמצאו ערכים דומים</div>
      </v-select>
    </label>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
  name: "FilterString",
  props: ["value", "options", "optionsAsync", "clearable"],
  components: {
    vSelect,
  },
  computed: {
    sortedList() {
      if (this.data) {
        const clone = this.data;
        return clone.sort(this.sort);
      }
      return [];
    },
  },
  data() {
    return {
      data: [],
    };
  },
  created() {
    // if (this.optionsAsync) {
    //     this.optionsAsync()
    //         .then((r) => {
    //             this.data = r;
    //         });
    // } else {
    //     this.data = this.options.slice(0);
    // }
  },
  methods: {
    filter(options, search) {
      const newOptions = options.filter((option) => {
        const label = option.Text;
        return label.includes(search.trim());
        // return label.includes(search.match(/[^\s]+/g));
      });
      return newOptions;
    },
    // handlers: (map, vm) => ({
    //   ...map,
    //   32: (e) => {
    //     console.log("vm:", vm);
    //     e.preventDefault();
    //   },
    // }),
    setSelected(value) {
      this.$emit("input", value);
    },
    sort(a, b) {
      return a.Text - b.Text;
    },
    fetchOptions(search, loading) {
      if (search) {
        loading(true);
        this.optionsAsync(search)
          .then((r) => {
            this.data = r;
          })
          .finally(() => {
            loading(false);
          });
      }
    },
  },
};
</script>
