<template>
  <div class="Field__Component">
    <div class="icon" v-if="value">
      <b-button :loading="isLoading" @click.stop="preview(item)">
        <b-icon icon="download-circle" />
      </b-button>
    </div>
  </div>
</template>

<script>
import FilesService from "@/services/FilesService";
import VueIframeWritable from "@/components/VueIframeWritable.vue";
import LeasingCompaniesService from "@/services/LeasingCompaniesService";
import { saveAs } from "file-saver";
import { ToastProgrammatic as Toast } from "buefy";
import printer from "../../plugins/printer";

export default {
  name: "FieldFileByTransferId",
  props: ["value", "item"],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isTransfersDrivers() {
      return !this.item.Id;
    },
  },
  methods: {
    preview(value) {
      const loadingComponent = this.$buefy.loading.open();
      const scrollPosition = document.body.querySelector('.table-wrapper').scrollTop;
      LeasingCompaniesService.printTransfersByBatchItemId([value.TransferBatchItemId])
        .then((r) => {
          this.$iframe.open({
            html: r.data?.Html,
            component: VueIframeWritable,
            onSave: async ({ close, loading }) => {
              loading(true);
              this.downloadFile(this.value).finally(() => {
                loading(false);
                close();
              });
            },
            onPrint: async ({ loading }) => {
              loading(true);
              printer.printHtml(r.data.Html, scrollPosition);
              loading(false);
            },
          });
        })
        .finally(() => {
          loadingComponent.close();
        });
    },

    downloadFile(url) {
      this.isLoading = true;
      return FilesService.getTransferPdf(url, {
        // headers: { "Profile-Id": this.item.Owner.Id },
      })
        .then((r) => {
          if (r.data) {
            const blob = new Blob([r.data], { type: "application/pdf" });
            saveAs(blob, "הסבה.pdf");
          }
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "המערכת נתקלה בבעיה! יש לנסות שוב או לפנות לתמיכה הטכנית.",
            position: "is-bottom",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Field__Component {
  width: 100%;
  text-align: center;
  ::v-deep button {
    border: none;
    background: transparent;

    &:focus,
    &:active {
      box-shadow: none;
    }
  }
  .icon {
    transition: transform 0.1s linear;
    cursor: pointer;

    &:active {
      transform: scale(0.98);
    }
  }
}
</style>
