var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "label",
      { attrs: { for: "" } },
      [
        _c("span", [_vm._t("default")], 2),
        _c(
          "b-dropdown",
          {
            staticClass: "dropdown-fields",
            attrs: {
              "aria-role": "menu",
              scrollable: "",
              "max-height": "400",
              "trap-focus": "",
              multiple: "",
              position: "is-bottom-right"
            },
            scopedSlots: _vm._u([
              {
                key: "trigger",
                fn: function() {
                  return [
                    _c("b-button", [
                      _vm._v(" " + _vm._s(_vm.getItemNames()) + " ")
                    ])
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.selectedItems,
              callback: function($$v) {
                _vm.selectedItems = $$v
              },
              expression: "selectedItems"
            }
          },
          [
            _c(
              "b-dropdown-item",
              {
                staticClass: "search-sticky",
                attrs: { custom: "", "aria-role": "listitem", focusable: "" }
              },
              [
                _c("b-input", {
                  attrs: { autofocus: "", placeholder: "חפש שדה..." },
                  model: {
                    value: _vm.columnField,
                    callback: function($$v) {
                      _vm.columnField = $$v
                    },
                    expression: "columnField"
                  }
                })
              ],
              1
            ),
            _vm._l(_vm.filteredPageList, function(item) {
              return _c(
                "b-dropdown-item",
                {
                  key: item.Value,
                  attrs: {
                    paddingless: "",
                    value: item.Value,
                    "aria-role": "listitem"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setSelected(item)
                    }
                  }
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "drop-down-field-item",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c("div", [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          domProps: { checked: _vm.isCheckedColumn(item.Value) }
                        }),
                        _c("span", { staticClass: "checkmark" }),
                        _vm._v(" " + _vm._s(item.Text) + " ")
                      ])
                    ]
                  )
                ]
              )
            }),
            _c(
              "b-dropdown-item",
              {
                staticClass: "search-sticky-bottom filters_actions",
                attrs: { custom: "", "aria-role": "listitem", focusable: "" }
              },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-info is-light" },
                    on: { click: _vm.resetColumns }
                  },
                  [_vm._v("בטל הכל")]
                ),
                _c(
                  "b-button",
                  { attrs: { type: "is-info" }, on: { click: _vm.selectAll } },
                  [_vm._v("בחר הכל")]
                ),
                _vm.isTransferOnly
                  ? _c(
                      "b-button",
                      {
                        attrs: { type: "is-primary is-light" },
                        on: { click: _vm.TransferOnly }
                      },
                      [_vm._v("הסבה בלבד")]
                    )
                  : _vm._e(),
                _vm.isManagedOnly
                  ? _c(
                      "b-checkbox",
                      {
                        attrs: { type: "is-info" },
                        model: {
                          value: _vm.isManaged,
                          callback: function($$v) {
                            _vm.isManaged = $$v
                          },
                          expression: "isManaged"
                        }
                      },
                      [_vm._v("מנוהלים בלבד")]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }