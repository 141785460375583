<template>
    <div class="Field__Component">
        <div><slot>
            <router-link :style="{ textDecoration: 'underline' }" target="_blank" :to="`/course/${courseId}/exercisePrint/${item.exerciseId}`">שאלון</router-link>
        </slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldStandard',
    props: ['value', 'item'],
    computed: {
        courseId() {
            return this.$store.state.auth.course?.id
        }
    }
};
</script>

<style scoped>

</style>
course/31/exercisePrint/3642