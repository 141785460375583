import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const getTransferPdf = (url, options) => axios.get(url, { responseType: 'blob', ...options });
const postData = (data) => axios.post(`${BASE_URL}/api/ExcelData/ToXls`, data, { responseType: 'blob' });
const getData = (file) => axios.post(`${BASE_URL}/api/ExcelData`, file, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});
const uploadTollRoadExcel = (file, data) => axios.post(`${BASE_URL}/api/TollRoadReports/Batch/${data.type}?comment=${data.comment}&fileName=${data.filename}`, file, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

export default {
    getTransferPdf,
    uploadTollRoadExcel,
    getData,
    postData,
};
