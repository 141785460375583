var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "div",
      [
        _vm._t("default", [
          _c(
            "router-link",
            {
              style: { textDecoration: "underline" },
              attrs: {
                target: "_blank",
                to:
                  "/course/" +
                  _vm.courseId +
                  "/exercisePrint/" +
                  _vm.item.exerciseId
              }
            },
            [_vm._v("שאלון")]
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }