<template>
    <div class="Field__Component">
        <label for="" class="Field__Component__Label">
            <span><slot></slot></span>
                <v-select  dir="rtl" label="Text"
                    :options="data"
                    :clearable="clearable"
                    :placeholder="'בחר ערך מהרשימה'"
                    :reduce="item => item.Value" @input="setSelected" :value="value">
                        <div slot="no-options">לא נמצאו ערכים דומים</div>
                </v-select>
        </label>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/src/scss/vue-select.scss';

export default {
    name: 'FilterString',
    props: ['value', 'options', 'optionsAsync', 'clearable', 'filter'],
    components: {
        vSelect,
    },
    computed: {
        sortedList() {
            if (this.data) {
                const clone = this.data;
                return clone.sort(this.sort);
            }
            return [];
        },
    },
    data() {
        return {
            data: [],
        };
    },
    created() {
        if (this.optionsAsync) {
            this.optionsAsync()
                .then((r) => {
                    this.data = r;
                    if (this.filter.initial && !this.value) {
                        this.setSelected(this.filter.initial(this.data));
                    }
                    // if (typeof this.value === 'object') {
                    //     this.data.find(i=>i.Value === this.value)
                    // }
                });
        } else {
            this.data = this.options.slice(0);
        }
    },
    methods: {
        setSelected(value) {
            this.$emit('input', value);
        },
        sort(a, b) {
            return a.Text - b.Text;
        },
    },
};
</script>
