<template>
    <div class="Field__Component">
        <div>
            <slot>{{ getExpoType(value) }}</slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FieldStandard',
    props: ['value'],
    methods: {
        getExpoType(type) {
            switch (type) {
                case 0:
                    return 'תרגול';
                case 1:
                    return 'וידאו';
                case 2:
                    return 'סימולציה';

                default:
                    return '';
            }
        }
    }
};
</script>

<style scoped></style>
