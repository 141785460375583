<template>
    <div class="Field__Component">
        <span>{{label}}</span>
    </div>
</template>

<script>
export default {
    name: 'FieldAccidentPdfStatus',
    props: ['value'],
    computed: {
        label() {
            switch (this.value) {
                case 0:
                    return '-';
                case 1:
                    return 'הושלם';
                case 2:
                    return 'ממתין';
                default:
                    return '-';
            }
        },
    },
};
</script>

<style>

</style>
