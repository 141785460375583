<template>
    <div class="Field__Component">
        <template v-if="isLoading">
            <div class="loading">
                <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
            </div>
        </template>
        <div>
            <span style="padding: 5px" @click.stop="setSortOrder(item, data.index, 1)"><b-icon size="is-small" icon="arrow-down"
                    class="sort__order__button">+</b-icon></span>
            <slot class="sort__order__value">{{ value }}</slot>
            <span style="padding: 5px" @click.stop="setSortOrder(item, data.index, -1)"><b-icon size="is-small" icon="arrow-up"
                    class="sort__order__button">-</b-icon></span>
        </div>
    </div>
</template>

<script>
import LessonService from '../../services/LessonService';

export default {
    name: 'FieldStandard',
    props: ['value', 'item', 'items', 'data', 'search'],
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
        setSortOrder(currItem, idx, action) {
            const currentItem = currItem;
            const itemToChange = this.items[idx + action];
            if (!itemToChange) return;
            if (action === 1) {
                itemToChange.sortOrder = currentItem.sortOrder;
                currentItem.sortOrder += 1;
            } else {
                itemToChange.sortOrder = currentItem.sortOrder;
                currentItem.sortOrder -= 1;
            }
            const model = {
                currentItem: {
                    id: currentItem.id,
                    sortOrder: currentItem.sortOrder
                },
                itemToChange: {
                    id: itemToChange.id,
                    sortOrder: itemToChange.sortOrder
                }
            }
            this.isLoading = true;
            LessonService.setLessonsSortOrder(model)
                .then(() => {
                    this.isLoading = false;
                    this.search();
                })
        }
    }
};
</script>

<style scoped lang="scss">
.sort__order__button {
    padding: 5px;
    background-color: #1eb2d0;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    height: 17px;
    width: 17px;
}

.sort__order__value {
    width: 15px;
    margin: 0 10px;
    display: inline-block;
    text-align: center;
}
</style>
