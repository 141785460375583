var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "div",
      [
        _vm._t("default", [
          _vm.value
            ? _c(
                "a",
                {
                  attrs: { target: "_blank", href: _vm.value },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "is-primary", size: "is-small" } },
                    [_vm._v("שלם")]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }