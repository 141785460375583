<template>
    <div class="Field__Component"
    :class="{
        paid: isPaid,
        'double-fine': isDobuleFine,
        open: isOpen,
        'is-declined': isDeclined,
        'is-appeal': isAppealRequested,
        'open-cancled-double': isOpenCanceledDouble
    }">
        <div>{{value}}</div>
    </div>
</template>

<script>
export default {
    name: 'FieldStandard',
    props: ['value'],
    computed: {
        isPaid() {
            return this.value === 'שולם';
        },
        isDobuleFine() {
            return this.value === 'כפל קנס';
        },
        isOpen() {
            return this.value === 'פתוח';
        },
        isOpenCanceledDouble() {
            return this.value === 'פתוח (בוטל כפל)';
        },
        isDeclined() {
            return this.value === 'ערעור נדחה';
        },
        isAppealRequested() {
            return this.value === 'הוגש ערעור';
        },
    },
};
</script>

<style scoped lang="scss">
    .Field__Component {
        text-align: center;
        background: white;
        text-align: center;
        border-radius: 3px;
        border: 1px solid;
    }
    .paid {
        color: #56af56;
    }
    .double-fine {
        color: #ff6161;
    }
    .open {
        color: #0badd4;
    }
    .open-cancled-double {
        color: #ff7a59;
    }
    .is-declined {
        color: red;
    }
    .is-appeal {
        color:orange;
    }
</style>
