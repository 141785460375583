var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Field__Component",
      class: ((_obj = {}), (_obj[_vm.align] = _vm.align), _obj)
    },
    [_c("div", [_vm._v(_vm._s(_vm.date))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }