var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "label",
      { attrs: { for: "" } },
      [
        _c("span", [_vm._t("default")], 2),
        _c(
          "v-select",
          {
            attrs: {
              dir: "rtl",
              label: "Text",
              value: _vm.value,
              options: _vm.data,
              multiple: true,
              searchable: true,
              clearable: _vm.clearable,
              "close-on-select": false,
              placeholder: "בחר ערכים מהרשימה",
              reduce: function(item) {
                return item.Value
              }
            },
            on: { input: _vm.setSelected }
          },
          [
            _c("div", { attrs: { slot: "no-options" }, slot: "no-options" }, [
              _vm._v("לא נמצאו ערכים דומים")
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }