var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Field__Component",
      class: {
        paid: _vm.isPaid,
        "double-fine": _vm.isDobuleFine,
        open: _vm.isOpen,
        "is-declined": _vm.isDeclined,
        "is-appeal": _vm.isAppealRequested,
        "open-cancled-double": _vm.isOpenCanceledDouble
      }
    },
    [_c("div", [_vm._v(_vm._s(_vm.value))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }