import FilterString from '@/components/Filters/FilterString.vue';
import FilterNumber from '@/components/Filters/FilterNumber.vue';
import FilterBoolean from '@/components/Filters/FilterBoolean.vue';
import FilterSelect from '@/components/Filters/FilterSelect.vue';
import FilterMultiSelect from '@/components/Filters/FilterMultiSelect.vue';
import FilterMultiDropdown from '@/components/Filters/FilterMultiDropdown.vue';
import FilterTypeAhead from '@/components/Filters/FilterTypeAhead.vue';
import FilterDateTypes from '@/components/Filters/FilterDateTypes.vue';
import FilterDate from '@/components/Filters/FilterDate.vue';

export default {
    FilterString,
    FilterNumber,
    FilterBoolean,
    FilterSelect,
    FilterMultiSelect,
    FilterMultiDropdown,
    FilterTypeAhead,
    FilterDateTypes,
    FilterDate
};
