<template>
    <div class="Field__Component">
        <label>
            <span><slot></slot></span>
            <b-switch
                class="ltr is-align-self-flex-start"
                v-model="model"></b-switch>
        </label>
    </div>
</template>

<script>

export default {
    name: 'FilterBoolean',
    props: ['value'],
    computed: {
        model: {
            set(value) {
                this.$emit('input', value);
            },
            get() {
                 return this.value;
            },
        },
    },
};
</script>
