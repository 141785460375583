<template>
    <div class="Field__Component" :class="{paid: isPaid, 'double-fine': isDobuleFine}">
        <div v-for="(alert,index) in value" :key="index">{{alert.Name}}</div>
    </div>
</template>

<script>
export default {
    name: 'FieldAlerts',
    props: ['value'],
    computed: {
        isPaid() {
            return this.value === 'שולם';
        },
        isDobuleFine() {
            return this.value === 'כפל קנס';
        },
    },
};
</script>

<style scoped lang="scss">
    .Field__Component {
        text-align: center;
    }
    .paid {
        background: #56af56;
        color: white;
        text-align: center;
        border-radius: 3px;
    }
    .double-fine {
        background: #ff6161;
        color: white;
        text-align: center;
        border-radius: 3px;
    }
</style>
