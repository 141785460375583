<template>
    <div class="Field__Component">
        <div style="width: 30px; height: 20px;" :style="{backgroundColor: getBackgroundColor()}" class="red" ><slot></slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldStandard',
    props: ['value'],
    methods: {
        getBackgroundColor() {
            return this.value ? this.value.split(';')[0] : '#5bc0de';
        }
    }
};
</script>

<style scoped>

</style>
