<template>
    <div v-if="value" class="Field__Component">
        <div v-if="!field.onClick"><slot>
            <a  @click.stop.prevent="onClick">
        {{value && value.Name}} </a></slot></div>
        <div v-else><slot>
            <p>{{ value && value.Name }}</p>
        </slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldEnum',
    props: ['value', 'item', 'field'],
    methods: {
        onClick() {
             if (this.field.onClick) {
                 this.field.onClick(this);
             } else {
                 this.default();
             }
        },
        default() {
            if (this.field.isAdmin) {
                this.$router.push({
                    path: `/admin/${this.field.Namespace}/${this.value.Id}`,
                });
            } else {
                this.$router.push({
                    path: `/${this.field.Namespace}/${this.value.Id}`,
                });
            }
        },
    },
};
</script>

<style>

</style>
