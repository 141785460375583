<template>
    <div class="Field__Component">
        <div><slot>{{value}}</slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldNumeric',
    props: ['value'],
};
</script>

<style>

</style>
