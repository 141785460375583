<template>
  <transition name="fade">
    <div v-if="open" class="Overlay__Component" :class="{transparent}">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    open: Boolean,
    transparent: Boolean,
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped lang="scss">
.Overlay__Component {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #42414163;
  // backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  z-index: 99;

  &.transparent {
    background-color: transparent;
    pointer-events: none;

    ::v-deep .SideMenu__Component {
      box-shadow: -3px 0 8px 0 rgb(66,91,118);
      pointer-events: auto;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
