var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "div",
      {
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.resetExercise(_vm.item)
          }
        }
      },
      [
        _vm._t("default", [
          _c(
            "b-button",
            {
              attrs: { label: "", type: "is-danger" },
              model: {
                value: _vm.item.wasResearched,
                callback: function($$v) {
                  _vm.$set(_vm.item, "wasResearched", $$v)
                },
                expression: "item.wasResearched"
              }
            },
            [_vm._v("איפוס")]
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }