<template>
    <div class="Field__Component">
        <div><slot>
            <a @click.stop target="_blank" :href="value" v-if="value">
                <b-button type="is-primary" size="is-small">שלם</b-button>
            </a>
        </slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldPayUrl',
    props: ['value', 'item'],
};
</script>

<style scoped>
    .Field__Component a {
        text-align: center;
        display: block;
    }
</style>
