var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "div",
      [
        _vm._t("default", [
          _vm.field.isAdmin
            ? _c(
                "router-link",
                { attrs: { to: "/admin/reports/" + _vm.item.Id } },
                [_vm._v(_vm._s(_vm.value))]
              )
            : _c("router-link", { attrs: { to: "/reports/" + _vm.item.Id } }, [
                _vm._v(_vm._s(_vm.value))
              ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }