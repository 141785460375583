<template>
    <div class="Field__Component">
        <div><slot><router-link :to="`/drivers/${item.Id}`">
        {{value && value.Name}}</router-link></slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldDriver',
    props: ['value', 'item'],
};
</script>

<style>

</style>
