<template>
    <div class="Field__Component">
        <div @click.stop="setExerciseWasResearched(item)">
            <slot>
                <b-checkbox v-model="item.wasResearched" label="" type="is-link is-light" class="checkbox"></b-checkbox>
            </slot>
        </div>
    </div>
</template>

<script>
import UserService from "@/services/UserService";

export default {
    name: 'FieldStandard',
    props: ['value', 'item'],
    computed: {
        courseId() {
            return this.$store.state.auth.course?.id
        }
    },
    methods: {
        setExerciseWasResearched(item) {
            // this.isLoading = true;
            UserService.updateUserExercise(item.userId, item.exerciseId, this.courseId)
                .then(() => {
                    this.$buefy.toast.open({
                        type: "is-success",
                        message: `התרגול תוחקר בהצלחה!`,
                        duration: 4000,
                    });
                    // item.wasResearched = !item.wasResearched;
                }).catch(() => {
                    this.$buefy.toast.open({
                        type: "is-danger",
                        message: `הפעולה נכשלה!`,
                        duration: 8000,
                    });
                }).finally(() => {
                    // this.isLoading = false;
                })
        }
    }
};
</script>

<style scoped></style>
course/31/exercisePrint/3642