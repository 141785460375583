<template>
    <div class="Field__Component" :class="{complete: model}">
        <div><slot>
            <button @click="complete">
            <b-icon
            :icon="model ? 'check-circle': 'check-circle-outline'" />
        </button>
            </slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldIsComplete',
    props: ['value', 'field'],
    data() {
        return {
            model: null,
        };
    },
    created() {
        this.model = this.value;
    },
    methods: {
        complete() {
            this.model = !this.model;
            // EntityItemsService.complete(this.entityType, this.entityId,
            //     this.item.Id, this.model);
        },
    },
};
</script>

<style scoped lang="scss">
.Field__Component {
    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    .icon {
        color: silver;

    }
    &.complete {
        .icon {
            color: #00bda5;
        }
        span {
            text-decoration: line-through;
        }
    }
}
</style>
