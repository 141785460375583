var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [_vm._v("פנייה")]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _vm.isLoading
            ? [
                _c(
                  "div",
                  { staticClass: "loading" },
                  [
                    _c("b-loading", {
                      attrs: { "is-full-page": false, "can-cancel": false },
                      model: {
                        value: _vm.isLoading,
                        callback: function($$v) {
                          _vm.isLoading = $$v
                        },
                        expression: "isLoading"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c("div", { staticClass: "DialogReportPaid__Component__Loading" }, [
            _c("p", [
              _vm._v(
                "* התלמיד יכול להגיב רק על פנייה בסטטוס בטיפול שהתגובה האחרונה היא של צוות הקורס"
              )
            ]),
            _c(
              "div",
              { staticClass: "responses" },
              _vm._l(_vm.responses, function(response, idx) {
                return _c(
                  "div",
                  {
                    key: response.id,
                    staticClass: "response",
                    class: response.isAdminResponse
                      ? "admin-response"
                      : "user-response"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "response-seen" },
                      [
                        response.isViewedByStudent
                          ? _c("b-icon", {
                              attrs: { title: "נצפה", pack: "fa", icon: "eye" }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.isEditModal !== idx
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(response.text) }
                        })
                      : _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: response.text,
                              expression: "response.text"
                            }
                          ],
                          attrs: { type: "text", name: "", id: "" },
                          domProps: { value: response.text },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(response, "text", $event.target.value)
                            }
                          }
                        }),
                    _c("div", { staticClass: "response-buttons" }, [
                      response.isAdminResponse
                        ? _c(
                            "div",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteMessage(response.id)
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                staticClass: "delete",
                                attrs: { title: "מחק", pack: "fa", icon: "" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      response.isAdminResponse
                        ? _c(
                            "div",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.openEditModal(idx)
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                staticClass: "pen",
                                attrs: {
                                  title: "ערוך",
                                  pack: "fa",
                                  icon: "pen"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isEditModal === idx
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                "margin-right": "auto"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.editResponse(
                                    response.id,
                                    response.text
                                  )
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                staticClass: "save",
                                attrs: {
                                  title: "ערוך",
                                  pack: "fa",
                                  icon: "save"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "ActivityComment__Component__Box__Content" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "ActivityComment__Component__Box__Content__Edit"
                  },
                  [
                    _c("NewActivityEditor", {
                      ref: "editor",
                      attrs: {
                        content: _vm.newMessage,
                        placeholder: "תגובה...",
                        animation: "''"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        ],
        2
      ),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }