<template>
   <div
    id="VueIframeWritable__Component"
    class="modal is-active"
    :class="[customClass]"
    tabindex="-1"            >
        <div class="modal-background" @click="cancel()"/>
        <div class="Actions">
        <b-tooltip label="הורדת קובץ" position="is-bottom">
            <b-button @click="download" :loading="isLoadingDownload">
                <b-icon icon="download" />
            </b-button>
        </b-tooltip>
        <b-tooltip label="הדפסה" position="is-bottom">
            <b-button @click="print" :loading="isLoadingPrint">
                <b-icon icon="printer" />
            </b-button>
        </b-tooltip>
        </div>
        <div
            class="animation-content">
            <iframe id="watch-document" name="watch-document" />
        </div>
    </div>
</template>

<script>
function removeElement(el) {
    if (typeof el.remove !== 'undefined') {
        el.remove();
    } else if (typeof el.parentNode !== 'undefined' && el.parentNode !== null) {
        el.parentNode.removeChild(el);
    }
}

export default {
    name: 'VueIframeWritable',
    props: {
        html: String,
        onCancel: {
            type: Function,
            default: () => {},
        },
        onSave: {
            type: Function,
            default: () => {},
        },
        onPrint: {
            type: Function,
            default: () => {},
        },
        customClass: String,

    },
    data() {
        return {
            isLoadingDownload: false,
            isLoadingPrint: false,
        };
    },
    methods: {
        download() {
            this.$emit('OnSave');
            this.onSave({ close: this.cancel, loading: this.setLoadingDownload });
        },
        print() {
            this.$emit('OnPrint');
            this.onPrint({ close: this.cancel, loading: this.setLoadingPrint });
        },
        setLoadingDownload(value) {
            this.isLoadingDownload = value;
        },
        setLoadingPrint(value) {
            this.isLoadingPrint = value;
        },
        cancel() {
            this.$emit('OnCancel');
            this.onCancel.apply(null);
            this.close();
        },
        close() {
            this.isActive = false;
                setTimeout(() => {
                    this.$destroy();
                    removeElement(this.$el);
                }, 150);
        },
    },
    mounted() {
        setTimeout(() => {
            const self = this;
            const frame = window.frames['watch-document'];
            if (frame) {
                frame.document.write(self.html);
            }
        }, 1000);
    },
    beforeMount() {
        document.body.appendChild(this.$el);
    },

};
</script>
<style scoped lang="scss">
    #VueIframeWritable__Component {
        position: fixed;
        z-index: 100;
        padding: 10px;

        .Actions {
            position: absolute;
            right: 0;
            top: 0;
            padding: 10px;

            ::v-deep {
                .button {
                    background: transparent;
                    border: none;
                    color: white;
                    margin: 0 5px;

                    :active {
                        transform: scale(.98);
                        border: none;
                    }
                    :focus {
                        border: none;
                    }
                }
            }
        }
    }
    .animation-content {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1;
        width: 1100px;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
</style>
