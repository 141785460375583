var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "div",
      [
        _vm._t("default", [
          _c(
            "b-button",
            {
              attrs: { icon: "arrow-up", type: "is-info", size: "is-small" },
              on: { click: _vm.openChatModal }
            },
            [_vm._v("פתח צ'אט")]
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }