var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Field__Component" },
    [
      _c(
        "b-button",
        { attrs: { type: "is-primary" }, on: { click: _vm.show } },
        [_vm._v("צפה")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }