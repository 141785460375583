<template>
    <div class="Field__Component">
        <label for="">
            <span><slot></slot></span>
            <v-select  dir="rtl" label="Text"
                    :value="value"
                    :options="data"
                    :multiple="true"
                    :searchable="true"
                    :clearable="clearable"
                    :close-on-select="false"
                    :placeholder="'בחר ערכים מהרשימה'"
                    @input="setSelected"
                    :reduce="item => item.Value">
                        <div slot="no-options">לא נמצאו ערכים דומים</div>
                    </v-select>
            <!-- <select name='' @input="test($event)">
                <option v-for="(item, index) in sortedList"
                        :selected="value==item.Value" :key="index" :value="item.Value">
                    {{item.Text}}
                </option>
            </select> -->
        </label>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/src/scss/vue-select.scss';

export default {
    name: 'FilterMultiSelect',
    props: ['value', 'options', 'optionsAsync', 'clearable'],
    components: {
        vSelect,
    },
    computed: {
        sortedList() {
            if (this.data) {
                const clone = this.data;
                return clone.sort(this.sort);
            }
            return [];
        },
    },
    data() {
        return {
            data: [],
        };
    },
    created() {
        if (this.optionsAsync) {
            this.optionsAsync()
                .then((r) => {
                    this.data = r;
                });
        } else {
            this.data = this.options.slice(0);
        }
    },
    methods: {
        setSelected(value) {
            this.$emit('input', value);
        },
        sort(a, b) {
            return a.Text - b.Text;
        },
    },
};
</script>
