<template>
    <div class="Field__Component">
        <div>
            {{ value ? computedValue : '-' }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'FieldPriority',
    props: ['value'],
    computed: {
        computedValue() {
            if (this.value) {
                switch (this.value) {
                    case 1:
                        return 'גבוהה';
                    case 2:
                        return 'רגילה';
                    case 3:
                        return 'נמוכה';
                    default:
                        return null;
                }
            }
            return null;
        },
    }
};
</script>

<style scoped></style>
