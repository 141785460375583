<template>
    <div class="Field__Component">
        <!-- <div><slot>{{ getRequestStatus }}</slot></div> -->
        <div><slot><span :style="{backgroundColor: setClassByStatus}" class="dot"></span>{{ getRequestStatus }}</slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldStandard',
    props: ['value'],
    computed: {
        getRequestStatus() {
            switch (this.value) {
                case 1:
                    return 'חדשה'
                case 2:
                    return 'בטיפול'
                case 3:
                    return 'הסתיימה'
            
                default:
                    return '';
            }
        },
        setClassByStatus() {
            switch (this.value) {
                case 1:
                    return '#efd83e';
                case 2:
                    return '#bcd891';
                case 3:
                    return '#a6c3d8';
                default:
                    return '';
            }
        },
    }
};
</script>

<style scoped lang="scss">
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
</style>
