var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal is-active",
      class: [_vm.customClass],
      attrs: { id: "VueIframeWritable__Component", tabindex: "-1" }
    },
    [
      _c("div", {
        staticClass: "modal-background",
        on: {
          click: function($event) {
            return _vm.cancel()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "Actions" },
        [
          _c(
            "b-tooltip",
            { attrs: { label: "הורדת קובץ", position: "is-bottom" } },
            [
              _c(
                "b-button",
                {
                  attrs: { loading: _vm.isLoadingDownload },
                  on: { click: _vm.download }
                },
                [_c("b-icon", { attrs: { icon: "download" } })],
                1
              )
            ],
            1
          ),
          _c(
            "b-tooltip",
            { attrs: { label: "הדפסה", position: "is-bottom" } },
            [
              _c(
                "b-button",
                {
                  attrs: { loading: _vm.isLoadingPrint },
                  on: { click: _vm.print }
                },
                [_c("b-icon", { attrs: { icon: "printer" } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "animation-content" }, [
      _c("iframe", { attrs: { id: "watch-document", name: "watch-document" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }