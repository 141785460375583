var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c("div", { staticClass: "Field__Component" }, [
        !_vm.field.onClick
          ? _c(
              "div",
              [
                _vm._t("default", [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.onClick($event)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.value && _vm.value.Name) + " ")]
                  )
                ])
              ],
              2
            )
          : _c(
              "div",
              [
                _vm._t("default", [
                  _c("p", [_vm._v(_vm._s(_vm.value && _vm.value.Name))])
                ])
              ],
              2
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }