var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _vm.value
      ? _c(
          "div",
          { staticClass: "icon" },
          [
            _c(
              "b-button",
              {
                attrs: { loading: _vm.isLoading },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.preview(_vm.item)
                  }
                }
              },
              [_c("b-icon", { attrs: { icon: "download-circle" } })],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }