import FieldStandard from '@/components/Fields/FieldStandard.vue';
import FieldRequestSubject from '@/components/Fields/FieldRequestSubject.vue';
import FieldNumeric from '@/components/Fields/FieldNumeric.vue';
import FieldCurrency from '@/components/Fields/FieldCurrency.vue';
import FieldBoolean from '@/components/Fields/FieldBoolean.vue';
import FieldRequestStatus from '@/components/Fields/FieldRequestStatus.vue';
import FieldDateHour from '@/components/Fields/FieldDateHour.vue';
import FieldVehicle from '@/components/Fields/FieldVehicle.vue';
import FieldDriver from '@/components/Fields/FieldDriver.vue';
import FieldVehicleType from '@/components/Fields/FieldVehicleType.vue';
import FieldFile from '@/components/Fields/FieldFile.vue';
import FieldEntity from '@/components/Fields/FieldEntity.vue';
import FieldFileByTransferId from '@/components/Fields/FieldFileByTransferId.vue';
import FieldRequestOpenChat from '@/components/Fields/FieldRequestOpenChat.vue';
import FieldFileDriver from '@/components/Fields/FieldFileDriver.vue';
import FieldStatus from '@/components/Fields/FieldStatus.vue';
import FieldLink from '@/components/Fields/FieldLink.vue';
import FieldSortOrder from '@/components/Fields/FieldSortOrder.vue';
import FieldColor from '@/components/Fields/FieldColor.vue';
import FieldUserType from '@/components/Fields/FieldUserType.vue';
import FieldExerciseQuestions from '@/components/Fields/FieldExerciseQuestions.vue';
import FieldExerciseAnswers from '@/components/Fields/FieldExerciseAnswers.vue';
import FieldExerciseResearch from '@/components/Fields/FieldExerciseResearch.vue';
import FieldExerciseReset from '@/components/Fields/FieldExerciseReset.vue';
import FieldLessonType from '@/components/Fields/FieldLessonType.vue';
import FieldCustomerLink from '@/components/Fields/FieldCustomerLink.vue';
import FieldPayUrl from '@/components/Fields/FieldPayUrl.vue';
import FieldAlerts from '@/components/Fields/FieldAlerts.vue';
import FieldImage from '@/components/Fields/FieldImage.vue';
import FieldEnum from '@/components/Fields/FieldEnum.vue';
import FieldExpoType from '@/components/Fields/FieldExpoType.vue';
import FieldIsComplete from '@/components/Fields/FieldIsComplete.vue';
import FieldHtml from '@/components/Fields/FieldHtml.vue';
import FieldPriority from '@/components/Fields/FieldPriority.vue';
import FieldEntityType from '@/components/Fields/FieldEntityType.vue';
import FieldAccidentType from '@/components/Fields/FieldAccidentType.vue';
import FieldAccidentPdfStatus from '@/components/Fields/FieldAccidentPdfStatus.vue';
import FieldAccidentPdf from '@/components/Fields/FieldAccidentPdf.vue';

export default {
    FieldStandard,
    FieldRequestSubject,
    FieldRequestOpenChat,
    FieldNumeric,
    FieldDateHour,
    FieldVehicle,
    FieldDriver,
    FieldVehicleType,
    FieldFile,
    FieldEntity,
    FieldFileByTransferId,
    FieldFileDriver,
    FieldStatus,
    FieldLink,
    FieldSortOrder,
    FieldColor,
    FieldUserType,
    FieldExerciseQuestions,
    FieldExerciseAnswers,
    FieldExerciseResearch,
    FieldExerciseReset,
    FieldLessonType,
    FieldBoolean,
    FieldRequestStatus,
    FieldCurrency,
    FieldCustomerLink,
    FieldPayUrl,
    FieldImage,
    FieldAlerts,
    FieldEnum,
    FieldExpoType,
    FieldIsComplete,
    FieldHtml,
    FieldPriority,
    FieldEntityType,
    FieldAccidentType,
    FieldAccidentPdfStatus,
    FieldAccidentPdf,
};
