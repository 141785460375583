<template>
    <div class="Field__Component">
        <div><slot> <img :src="value" alt=""></slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldImage',
    props: ['value', 'item'],
};
</script>

<style scoped>
img {
    width: 130px;
    height: 183px;
}
</style>
